.ms-Callout-container > .ms-Callout.ms-ContextualMenu-Callout {
    border: solid 1px var(--neutral-secondary-alt);
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 6.4px 14.4px 0px #00000021;
}

.ms-Callout-container .ms-ContextualMenu-linkContent > .ms-ContextualMenu-itemText:active,
.ms-Button.is-expanded.ms-Button--hasMenu {
    color: var(--theme-light);
}
